import { ProductPrintType } from '@zakeke/zakeke-customizer-react';
import { TextElement, CustomizerElement, TextArtElement, isTextElement } from '../components/interfaces';
import { PaletteColors } from '../state/interfaces/paletteColors';
import { uniqBy } from 'lodash';

/**
 * Check if a string has any content except space, new line, tab and similar
 * @param string
 * @returns bolean - if string content is emplty or space-like content
 */
export const isEmptyText = (s: string) => !/\S/gim.test(s);

/**
 * Check if item isFromTemplate and returns content or originalContent if there is no content.
 */
export const getItemContentOrDefault = (item: TextElement | TextArtElement, defaultContent: string): string => {
	if (!isEmptyText(item.content)) {
		return item.content;
	}
	if (item.isFromTemplate) {
		return item.originalContent || defaultContent
	}
	return item.content || defaultContent;
};

/**
 * Check if item isFromTemplate and if should be Uppercase
 */
export const getItemTextUppercase = (content: string, uppercase: boolean, isFromTemplate?: boolean, ): string => {

	return uppercase && !isFromTemplate ? content.toUpperCase() : content;
};

/**
 * transform item text to display canvas text
 */
export const getItemTextToCanvasText = (item: TextElement | TextArtElement, defaultContent: string, uppercase: boolean): string => {

	return getItemTextUppercase(getItemContentOrDefault(item, defaultContent), uppercase, item.isFromTemplate);
};

/**
 * returns original color or:
 *	1. print type selectableColors color limit
 *	2. print type color limit for text elements
 *	3. side used color in maxColor scenario
 */
export const getPrintTypeTextColor = (
	defaultTextColor: string,
	printType: ProductPrintType,
	selectableColors: PaletteColors[] | null,
	sideUsedColors?: string[] | null,
	useContrastColor?: string,
) => {
	if (sideUsedColors && printType && printType.maxColors && printType.maxColors - 1 <= sideUsedColors?.length) {
		if (useContrastColor) {
			const color = sideUsedColors.find(color => color.toLowerCase() !== useContrastColor.toLowerCase());
			const fallbackColor = printType.maxColors <= sideUsedColors?.length ? sideUsedColors[0] : defaultTextColor;
			defaultTextColor = color ? color : fallbackColor;
		} else {
			defaultTextColor = sideUsedColors[0];
		}
	}

	if (printType.textColors && printType.textColors.length > 0 && !printType.textColors.includes(defaultTextColor)) {
		const color = useContrastColor ? printType.textColors.find(color => color.toLowerCase() !== useContrastColor.toLowerCase()) : null;
		defaultTextColor = color ? color: printType.textColors[0];
	}
	if (selectableColors && selectableColors.length > 0 && !selectableColors.find((x) => x.hex === defaultTextColor)) {
		const color = useContrastColor ? selectableColors.find(color => color.hex.toLowerCase() !== useContrastColor.toLowerCase())?.hex : null;
		defaultTextColor = color ? color : (selectableColors?.[0].hex ?? null);
	}
	return defaultTextColor;
};

// #region default values

/**
 * Get initial colors based on print type default value/black or:
 *	1. print type selectableColors color limit
 *	2. print type color limit for text elements
 *	3. side used color in maxColor scenario
 */
export const getDefaultTextColor = (
	printType: ProductPrintType,
	selectableColors: PaletteColors[] | null,
	sideUsedColors?: string[] | null,
) => {
	return getPrintTypeTextColor(printType.defaultTextColor || '#000000', printType, selectableColors, sideUsedColors);
};
// #endregion


/**
 * Get initial colors based on print type default value/black or:
 *	1. print type selectableColors color limit
 *	2. print type color limit for text elements
 *	3. side used color in maxColor scenario
 */
 export const getDefaultTextStrokeColor = (
	printType: ProductPrintType,
	selectableColors: PaletteColors[] | null,
	textColor: string,
	sideUsedColors?: string[] | null,
) => {
	const newUsedColors = sideUsedColors ? uniqBy([textColor, ...sideUsedColors], i => i.toLowerCase()) : null;
	return getPrintTypeTextColor('#000000', printType, selectableColors, newUsedColors, textColor);
};
// #endregion



// #region Resctictions

/**
 * check if can change text only (not text art): stroke width , stroke color, what's stroke min width and stroke max width
 */
export const checkTextStrokeRestrictions = (
	item: CustomizerElement | null | undefined,
	currentPrintType: ProductPrintType | undefined,
) => {
	if (!item || !isTextElement(item)) {
		return {
			canChangeTextStrokeColor: false,
			canChangeTextStrokeWidth: false,
			minTextStrokeWidth: 0,
			maxTextStrokeWidth: 0,
		};
	}
	const allowTextStroke = currentPrintType?.allowTextStroke !== false;
	const _minTextStrokeWidth = currentPrintType?.minTextStrokeWidth;
	const _maxTextStrokeWidth = currentPrintType?.maxTextStrokeWidth;

	const minTextStrokeWidth = item.constraints?.minTextStrokeWidth
		? (item.constraints.minTextStrokeWidth as number)
		: _minTextStrokeWidth ?? null;

	const maxTextStrokeWidth = item.constraints?.maxTextStrokeWidth
		? (item.constraints.maxTextStrokeWidth as number)
		: _maxTextStrokeWidth ?? null;

	const fixedStrokeWidth =
		minTextStrokeWidth !== null && maxTextStrokeWidth !== null && minTextStrokeWidth >= maxTextStrokeWidth;

	const canChangeTextStrokeColor =
		allowTextStroke && (item.constraints ? item.constraints.canChangeTextStrokeColor : true);

	const canChangeTextStrokeWidth =
		allowTextStroke && !fixedStrokeWidth && (item.constraints ? item.constraints.canChangeTextStrokeWidth : true);

	return { canChangeTextStrokeColor, canChangeTextStrokeWidth, minTextStrokeWidth, maxTextStrokeWidth };
};

// #endregion
