import { useCallback, useEffect, useRef } from 'react';
import { atom, useAtomValue } from 'jotai';
import { shallow } from 'zustand/shallow';
import { identity } from 'lodash';
import {
	Design,
	DesignSide,
	ProductPrintType,
	useZakekeCollages,
	useZakekeHelpers,
	useZakekeEngraving,
	useZakekeShapesAndMasks,
	useZakekeMasks,
	useZakekeShapes,
	useZakekeImageGalleriesAvailable,
	useZakekePrice,
	useZakekeSettings,
} from '@zakeke/zakeke-customizer-react';

import {
	BackgroundRule,
	CustomizerElement,
	isImageElement,
	isTextArtElement,
	isTextElement,
} from '../components/interfaces';
import { checkConstraints } from '../state/utils';
import { checkTextStrokeRestrictions } from '../shared/helpers.text';
import { itemsSelectorFamily, useAppStore, useGetItems, useItemValueWithSelector } from '../state/store';
import { isItemStatic } from './common';
import { useBackgroundEditingTools } from './useBackgroundEditingTools';
import usePhotoEditorTool from './usePhotoEditorTool';
import usePrintMethods from './usePrintMethods';

// check if there are nonprintable elements only in template, if we are using one
export const areItemsPrintableAtom = atom((get) =>
	get(itemsSelectorFamily).some((x) => x.isFromTemplate !== true || x.constraints?.isPrintable !== false),
);

/**
 * check if item is immutable, not sortable, not deletable and not replaceable.
 * check if item is a fixed element setted from seller (like bleeding edges or legal info)
 * @param item
 * @returns boolean
 */
const _getIsFixedLayoutItem = (
	item: CustomizerElement,
	availableTools: {
		isReplaceEnabled: boolean;
		isDuplicateEnabled: boolean;
		isMoveFowardBackwardEnabled: boolean;
		isDeleteEnabled: boolean;
	},
) => {
	//const isItemBackground = isImageElement(item) && item.isBackgroundForArea && item.isBackgroundForArea > 0;
	const isItemReplaceableImage = isImageElement(item) && availableTools.isReplaceEnabled === true;
	if (isItemReplaceableImage) return false;
	const isItemImmovable =
		isItemStatic(item) &&
		(item.constraints?.isAlwaysOnBottom || item.constraints?.isAlwaysOnTop) &&
		!availableTools.isDuplicateEnabled &&
		!availableTools.isMoveFowardBackwardEnabled &&
		!availableTools.isDeleteEnabled;
	return isItemImmovable;
};

export type AvailableTools = ReturnType<ReturnType<typeof useAvailableToolsForItem>>;
/**
 * Return a list of all available tools and values
 * @param item if passed, it take care of item restrictions
 * @returns a list of available tools and values
 */
export const useAvailableToolsForItem = () => {
	const getItems = useGetItems();
	const { getPrintMethodForSide } = usePrintMethods();
	const { getSelectableColorsForText } = useZakekeHelpers();
	const [price] = useZakekePrice();
	const shapesAndMasks = useZakekeShapesAndMasks();
	const masks = useZakekeMasks();
	const shapes = useZakekeShapes();
	const imageGalleriesAvailable = useZakekeImageGalleriesAvailable();
	const { photoEditorLabelsFromBackend, photoEditorToolsList } = usePhotoEditorTool();
	const { isOrderDesignEditor, isInTestMode, isShopifyDevelopmentStore, isImageScalingEnabled } = useZakekeSettings();

	const collages = useZakekeCollages();
	const [selectedSideId, currentTemplate, sidesCollages] = useAppStore((x) => [
		x.selectedSideId,
		x.currentTemplate,
		x.sidesCollages,
	]);
	const backgroundRules = useBackgroundEditingTools();
	const { printTypesWithEngraving } = useZakekeEngraving();
	const areItemsPrintable = useAtomValue(areItemsPrintableAtom);

	return useCallback(
		(item?: CustomizerElement | null) => {
			const currentPrintType = getPrintMethodForSide(selectedSideId);

			const areSapesAndMaskForPrintTypeEnabled =
				shapesAndMasks?.printTypesIDs.some((el: Number) => el === currentPrintType?.id) ?? false;
			const areShapesEnabled =
				(shapesAndMasks?.forProduct || areSapesAndMaskForPrintTypeEnabled) && shapes.length > 0;
			const areMasksEnabled =
				(shapesAndMasks?.forProduct || areSapesAndMaskForPrintTypeEnabled) && masks.length > 0;

			const collage = sidesCollages.get(selectedSideId)
				? collages.find((x) => x.id === sidesCollages.get(selectedSideId))
				: undefined;
			if (!currentPrintType)
				throw new Error(
					'Ensure a print type is selected for the side ' +
						selectedSideId +
						' before using useAvailableTools.',
				);

			const currentTemplateSide = currentTemplate?.sides.find((x) => x.sideId === selectedSideId);

			const restrictions =
				!currentTemplateSide && !currentTemplate
					? currentPrintType
					: currentTemplateSide ?? currentTemplate ?? currentPrintType;

			let uploadFileFormats: string[] = getUploadFileFormats(restrictions) ?? [];
			const items = getItems();
			// TODO: Optimize dependency from 'items'
			const currentNumberOfImages = items.filter(isImageElement).length;
			const currentNumberOfTexts = items.filter(isTextElement).length;

			const filledCollageBoxes = items
				.filter((x) => x.sideId === selectedSideId)
				.filter((x) => x.collageBoxId !== null);
			const isCollageFilled = collage ? filledCollageBoxes.length >= collage.boxesCount : false;
			const hasCollage = !!collage;

			const maxNumberOfImages =
				currentTemplateSide?.maxNumberOfImages ??
				currentTemplate?.maxNumberOfImages ??
				currentPrintType.maxNumberOfImages;
			const maxNumberOfTexts =
				currentTemplateSide?.maxNumberOfTexts ??
				currentTemplate?.maxNumberOfTexts ??
				currentPrintType.maxNumberOfTexts;

			const sellerImageEnabled =
				currentPrintType.areClipartsImagesEnabled &&
				(currentTemplateSide
					? currentTemplateSide.areClipartsImagesEnabled
					: currentTemplate
					? currentTemplate?.areClipartsImagesEnabled
					: true);
			const areCustomersImagesEnabled = currentPrintType.areCustomersImagesEnabled;

			const isMaxNumberImagesReached =
				(maxNumberOfImages && currentNumberOfImages >= maxNumberOfImages) || isCollageFilled;
			const isMaxNumberTextsReached = maxNumberOfTexts && currentNumberOfTexts >= maxNumberOfTexts;

			const [printTypeEffect] = printTypesWithEngraving.filter((x) => x.id === currentPrintType.id);
			const canEngravingEffectChangeTextColor =
				!!printTypeEffect && printTypeEffect.previewDesignEffect.hasTextOverlay ? false : true;
			const canEngravingEffectChangeImageColor =
				!!printTypeEffect && printTypeEffect.previewDesignEffect.imageEffectTypeName.toLowerCase() === 'none'
					? false
					: true;

			const isLargeFileEnabled = currentPrintType.isLargeFileEnabled;

			// const isUploadAllFileEnabledCurrentTemplate =
			// 	currentTemplate?.isJpgUploadEnabled &&
			// 	currentTemplate?.isPngUploadEnabled &&
			// 	currentTemplate?.isSvgUploadEnabled &&
			// 	currentTemplate?.isEpsUploadEnabled &&
			// 	currentTemplate?.isAiUploadEnabled &&
			// 	currentTemplate?.isAiUploadEnabled &&
			// 	currentTemplate?.isFacebookEnabled &&
			// 	currentTemplate?.isPdfWithRasterEnabled;

			const isUploadAnyFileEnabledCurrentTemplateSide =
				currentTemplateSide?.isJpgUploadEnabled ||
				currentTemplateSide?.isPngUploadEnabled ||
				currentTemplateSide?.isSvgUploadEnabled ||
				currentTemplateSide?.isEpsUploadEnabled ||
				currentTemplateSide?.isAiUploadEnabled ||
				currentTemplateSide?.isAiUploadEnabled ||
				currentTemplateSide?.isFacebookEnabled ||
				currentTemplateSide?.isPdfWithRasterEnabled;

			const isUploadAnyFileEnabledCurrentTemplate =
				currentTemplate?.isJpgUploadEnabled ||
				currentTemplate?.isPngUploadEnabled ||
				currentTemplate?.isSvgUploadEnabled ||
				currentTemplate?.isEpsUploadEnabled ||
				currentTemplate?.isAiUploadEnabled ||
				currentTemplate?.isAiUploadEnabled ||
				currentTemplate?.isFacebookEnabled ||
				currentTemplate?.isPdfWithRasterEnabled;

			const isUploadAnyFileEnabledCurrentPrintType =
				currentPrintType.areCustomersImagesEnabled &&
				(currentPrintType?.isJpgUploadEnabled ||
					currentPrintType?.isPngUploadEnabled ||
					currentPrintType?.isSvgUploadEnabled ||
					currentPrintType?.isEpsUploadEnabled ||
					currentPrintType?.isAiUploadEnabled ||
					currentPrintType?.isFacebookEnabled ||
					currentPrintType?.isPremiumPhotosEnabled);

			const isAnyUploadEnabled =
				isUploadAnyFileEnabledCurrentTemplateSide ||
				isUploadAnyFileEnabledCurrentTemplate ||
				isUploadAnyFileEnabledCurrentPrintType;

			const isPremiumPhotosEnabled =
				!isOrderDesignEditor &&
				!isInTestMode &&
				!isShopifyDevelopmentStore &&
				price > 0 &&
				currentPrintType.isPremiumPhotosEnabled &&
				(currentPrintType.isPngUploadEnabled || currentPrintType.isJpgUploadEnabled);

			let areCustomersUploadImagesEnabled = (
				!currentTemplate || !currentTemplate
					? true
					: currentPrintType?.isJpgUploadEnabled &&
					  (currentTemplateSide?.isImageEnabled || currentTemplate?.isImageEnabled)
			)
				? true
				: (isUploadAnyFileEnabledCurrentTemplateSide || isUploadAnyFileEnabledCurrentTemplate) &&
				  (currentTemplateSide?.isImageEnabled ??
						currentTemplate?.isImageEnabled ??
						currentPrintType.isImageEnabled);

			const isClipartsImagesButtonEnabled =
				(currentTemplateSide?.isImageEnabled ??
					currentTemplate?.isImageEnabled ??
					currentPrintType.isImageEnabled) &&
				imageGalleriesAvailable &&
				sellerImageEnabled;

			const areClipartsImagesEnabled = imageGalleriesAvailable && sellerImageEnabled;

			const isTextEnabled =
				currentPrintType.isTextEnabled ?? currentTemplateSide?.isTextEnabled ?? currentTemplate?.isTextEnabled;

			const isTextArtEnabled = isTextEnabled && currentPrintType.isTextArtEnabled;

			const isImageFiltersAndEditingEnabled = currentPrintType.isImageFiltersAndEditingEnabled;

			/* Text */
			const canPrintTypeChangeFontColor = canEngravingEffectChangeTextColor && true;
			const canPrintTypeChangeFontFamily = true;
			const canPrintTypeChangeFontSize =
				currentPrintType.isTextFontSizeEnabled &&
				((currentPrintType.minTextFontSize === null && currentPrintType.maxTextFontSize === null) ||
					currentPrintType.minTextFontSize !== currentPrintType.maxTextFontSize);
			const canPrintTypeChangeLineSpacing =
				currentPrintType.isTextLineSpacingEnabled &&
				((currentPrintType.minTextLineSpacing === null && currentPrintType.maxTextLineSpacing === null) ||
					currentPrintType.minTextLineSpacing !== currentPrintType.maxTextLineSpacing);
			const canPrintTypeChangeLetterSpacing =
				currentPrintType.isTextLetterSpacingEnabled &&
				((currentPrintType.minTextLetterSpacing === null && currentPrintType.maxTextLetterSpacing === null) ||
					currentPrintType.minTextLetterSpacing !== currentPrintType.maxTextLetterSpacing);
			const canPrintTypeChangeAlignmnet =
				currentPrintType.isTextLeftAlignmentEnabled ||
				currentPrintType.isTextRightAlignmentEnabled ||
				currentPrintType.isTextCenterAlignmentEnabled ||
				currentPrintType.isTextJustifyAlignmentEnabled ||
				currentPrintType.isTextTopVerticalAlignmentEnabled ||
				currentPrintType.isTextMiddleVerticalAlignmentEnabled ||
				currentPrintType.isTextBottomVerticalAlignmentEnabled;

			const canPrintTypeChangeVerticalAlignment =
				currentPrintType.isTextTopVerticalAlignmentEnabled ||
				currentPrintType.isTextMiddleVerticalAlignmentEnabled ||
				currentPrintType.isTextBottomVerticalAlignmentEnabled;

			const canPrintTypeChangeShadowAngle =
				(currentPrintType.minShadowAngle === null && currentPrintType.maxShadowAngle == null) ||
				currentPrintType.minShadowAngle !== currentPrintType.maxShadowAngle;
			const canPrintTypeChangeShadowBlur =
				(currentPrintType.minShadowBlur === null && currentPrintType.maxShadowBlur == null) ||
				currentPrintType.minShadowBlur !== currentPrintType.maxShadowBlur;
			const canPrintTypeChangeShadowDistance =
				(currentPrintType.minShadowDistance === null && currentPrintType.maxShadowDistance == null) ||
				currentPrintType.minShadowDistance !== currentPrintType.maxShadowDistance;
			const canPrintTypeChangeShadowColor =
				currentPrintType.shadowColors == null ||
				currentPrintType.shadowColors.length === 0 ||
				currentPrintType.shadowColors.length > 1;

			const isTextUppercase =
				item?.constraints?.toUppercase ??
				(!!item && currentPrintType.isTextUppercase && !item?.isFromTemplate) ??
				false;

			/* Background editing tool */
			const isBackgroundEditingEnabled = backgroundRules ? true : false;

			/* TextArt */
			const canChangeTextArtColor = canEngravingEffectChangeTextColor && currentPrintType.isSvgRecolorEnabled;

			/* Final checks (print method + item constraint) */
			const canChangeFontColor = item?.constraints?.canChangeFontColor ?? canPrintTypeChangeFontColor;
			const canChangeFontFamily = item?.constraints?.canChangeFontFamily ?? canPrintTypeChangeFontFamily;
			const canChangeFontSize =
				item && item.constraints
					? (item?.constraints?.canResize ?? true) && (item?.constraints?.canChangeFontSize ?? true)
					: canPrintTypeChangeFontSize;
			const canChangeFontWeight = item?.constraints?.canChangeFontWeight ?? currentPrintType.isTextBoldEnabled; //in tpl item could check "Font weight/italic", that only sets canChangeFontWeight
			const canChangeFontItalic = item?.constraints?.canChangeFontWeight ?? currentPrintType.isTextItalicEnabled; //in tpl item could check "Font weight/italic", that only sets canChangeFontWeight
			const canChangeLineSpacing = item?.constraints?.canChangeLineSpacing ?? canPrintTypeChangeLineSpacing;
			const canChangeLetterSpacing = item?.constraints?.canChangeLetterSpacing ?? canPrintTypeChangeLetterSpacing;
			const canChangeAlignment = item?.constraints?.canChangeJustification ?? canPrintTypeChangeAlignmnet;
			const canChangeVerticalAlignment =
				item?.isTextBox &&
				(item?.constraints?.canChangeVerticalAlignment ?? canPrintTypeChangeVerticalAlignment ?? true);
			const canChangeTextBoxMode =
				item?.constraints?.canChangeTextBoxMode ?? currentPrintType.isTextBoxEnabled ?? true;
			const isMaskEnabled = areMasksEnabled && (item?.constraints?.canAddMask ?? true);

			const isUploadEnabled = currentPrintType.areCustomersImagesEnabled;
			const isReplaceEnabled =
				!!item &&
				isImageElement(item) &&
				(item?.constraints?.canEdit ?? true) &&
				(sellerImageEnabled || isUploadEnabled);
			const isMaskReplaceable = item?.constraints?.canReplaceMask ?? true;
			const isMaskDeletable = item?.constraints?.canDeleteMask ?? true;
			const canImageFiltersAndEditing =
				isImageFiltersAndEditingEnabled &&
				item &&
				isImageElement(item) &&
				!item.isVector &&
				!item.isBackgroundForArea;

			const canItemChangeTextPathMode = item?.constraints?.canChangeTextPathMode ?? true;
			const canPrintTypeChangeCurvedText = currentPrintType.isTextCurved;
			const canChangeTextPathMode = canItemChangeTextPathMode && canPrintTypeChangeCurvedText;

			const isDeletable = item?.constraints?.canDelete ?? true;

			let isDuplicateEnabled =
				!(item && isImageElement(item) && item?.isBackgroundForArea && item?.isBackgroundForArea > 0) &&
				(item?.constraints?.canTransform ?? true) &&
				currentPrintType.isDuplicateEnabled;

			let isMoveable = item?.constraints?.canMove ?? true;

			let isMoveFowardBackwardEnabled =
				item?.constraints?.isAlwaysOnTop || item?.constraints?.isAlwaysOnBottom
					? false
					: !(item && isImageElement(item) && item?.isBackgroundForArea && item?.isBackgroundForArea > 0) &&
					  isMoveable &&
					  (item?.constraints?.canTransform ?? true) &&
					  currentPrintType.isMoveFowardBackwardEnabled;

			let isFlipEnabled =
				!(item && isImageElement(item) && item?.isBackgroundForArea && item?.isBackgroundForArea > 0) &&
				(item?.constraints?.canTransform ?? true) &&
				currentPrintType.isFlipEnabled;

			if (item && isTextElement(item)) isFlipEnabled = false; // Flip don't work on texts

			if (item && isTextElement(item) && (!isTextEnabled || isMaxNumberTextsReached)) isDuplicateEnabled = false;

			if (item && isTextArtElement(item) && (!isTextEnabled || !isTextArtEnabled || isMaxNumberTextsReached))
				isDuplicateEnabled = false;

			if (item && isImageElement(item) && (!areCustomersUploadImagesEnabled || isMaxNumberImagesReached))
				isDuplicateEnabled = false;

			let maxListFontSize;
			if (currentPrintType.fontSizeList && currentPrintType.fontSizeList.length > 0) {
				maxListFontSize = currentPrintType.fontSizeList[currentPrintType.fontSizeList.length - 1];
			}

			/* questo valore è dato in punti tipografici, ma noi passiamo alla libreria un valore in px */
			const minFontSize = item?.constraints?.minFontSize ?? currentPrintType.minTextFontSize ?? 1;
			const maxFontSize =
				item?.constraints?.maxFontSize ?? currentPrintType.maxTextFontSize ?? maxListFontSize ?? undefined;
			const fontSizeList = currentPrintType.fontSizeList;
			const minLineSpacing = item?.constraints?.minLineSpacing ?? currentPrintType.minTextLineSpacing ?? -100;
			const maxLineSpacing = item?.constraints?.maxLineSpacing ?? currentPrintType.maxTextLineSpacing ?? 100;
			const minLetterSpacing =
				item?.constraints?.minLetterSpacing ?? currentPrintType.minTextLetterSpacing ?? -100;
			const maxLetterSpacing =
				item?.constraints?.maxLetterSpacing ?? currentPrintType.maxTextLetterSpacing ?? 100;

			const canPrintTypeRecolorImage = canEngravingEffectChangeImageColor;

			const canItemChangeShadow =
				item &&
				(!item.constraints ||
					item.constraints.canChangeShadowAngle ||
					item.constraints.canChangeShadowBlur ||
					item.constraints.canChangeShadowColor ||
					item.constraints.canChangeShadowDistance);

			const canChangeShadow = currentPrintType.isTextShadowsEnabled && canItemChangeShadow;
			const canChangeShadowAngle =
				canChangeShadow && checkConstraints(item, 'canChangeShadowAngle') && canPrintTypeChangeShadowAngle;
			const canChangeShadowBlur =
				canChangeShadow && checkConstraints(item, 'canChangeShadowBlur') && canPrintTypeChangeShadowBlur;
			const canChangeShadowDistance =
				canChangeShadow &&
				checkConstraints(item, 'canChangeShadowDistance') &&
				canPrintTypeChangeShadowDistance;
			const canChangeShadowColor =
				canChangeShadow && checkConstraints(item, 'canChangeShadowColor') && canPrintTypeChangeShadowColor;

			// performance consuming calculation
			const getDefaultShadowColor = () => {
				let defaultShadowColor = currentPrintType.defaultShadowColor;
				const shadowSelectableColors =
					item && isTextElement(item)
						? getSelectableColorsForText(item.sideId, item.shadowColor ?? null)
						: null;
				if (
					shadowSelectableColors &&
					shadowSelectableColors.length > 0 &&
					!shadowSelectableColors.find((x) => x.hex === defaultShadowColor)
				)
					defaultShadowColor = shadowSelectableColors[0].hex;
				return defaultShadowColor;
			};

			const areSellerImagesGalleryEnabled = backgroundRules?.enableImageSellerGallery ? true : false;
			const areBackgroundCustomersImagesEnabled = backgroundRules?.enableImageCustomerGallery ? true : false;

			const uploadFileFormatsBackground = () => {
				return filterUploadForBackground(backgroundRules, uploadFileFormats);
			};

			const isUploadFromYourComputerVisible = uploadFileFormats.length > 0;

			const isImagesScalingEnabled = currentPrintType.customerImagesScalingType === 1;
			const isClipartScalingEnabled = currentPrintType.clipartImagesScalingType === 1;
			const isClipartsResizeEnabled = currentPrintType.isClipartsImagesResizeEnabled;

			const canImageChangeColors =
				item?.constraints?.canChangeSvgColors ?? currentPrintType.isSvgRecolorEnabled ?? true;

			// ok ma il vecchio puntava solo sui files SVG !
			const canSVGImageChangeColors =
				canImageChangeColors &&
				item &&
				isImageElement(item) &&
				(item.isClipart || item.isVector) &&
				!!item.colors.length;

			const canShapeCangeFillColor =
				item?.constraints?.canChangeShapeFillColor ?? currentPrintType.isSvgRecolorEnabled ?? true;
			const canShapeChangeStrokeColor =
				item?.constraints?.canChangeShapeStrokeColor ?? currentPrintType.isSvgRecolorEnabled ?? true;
			const canShapeChangeStrokeWidth = item?.constraints?.canChangeShapeStrokeWidth ?? true;

			const isFixedLayoutItem =
				!!item &&
				_getIsFixedLayoutItem(item, {
					isReplaceEnabled: !!isReplaceEnabled,
					isDuplicateEnabled,
					isMoveFowardBackwardEnabled,
					isDeleteEnabled: isDeletable,
				});
			const filteredPhotoEditorLabels = photoEditorLabelsFromBackend.filter((label) =>
				currentPrintType.imageEditingToolSettingsToExclude?.every((labelToExclude) => label !== labelToExclude),
			);

			const availablePhotoEditorTools = filteredPhotoEditorLabels.map((label) => photoEditorToolsList[label]);

			const canResizeConstraint = item?.constraints?.canResize !== false;

			return {
				/* Tools */
				areShapesEnabled,
				areMasksEnabled,
				areCustomersUploadImagesEnabled,
				areClipartsImagesEnabled,
				isClipartsImagesButtonEnabled,
				areSellerImagesGalleryEnabled,
				areBackgroundCustomersImagesEnabled,
				isTextEnabled,
				isTextArtEnabled,
				isBackgroundEditingEnabled,
				isMaxNumberImagesReached,
				isMaxNumberTextsReached,
				isCollageFilled,
				hasCollage,
				canResizeConstraint,

				/* templates */
				currentTemplate,
				currentTemplateSide,

				/* Accessories */
				isPdfPreviewEnabled: currentPrintType.isPdfPreviewEnabled,

				/* Element actions  */
				isDeletable,
				isDuplicateEnabled,
				isFlipEnabled,
				isMoveable,
				isMoveFowardBackwardEnabled,
				isReplaceEnabled,
				isMaskEnabled,
				isMaskReplaceable,
				isMaskDeletable,
				canImageFiltersAndEditing,
				areItemsPrintable,

				/* Images upload */
				isAnyUploadEnabled,
				uploadFileFormats,
				uploadFileFormatsBackground,
				isUploadFromYourComputerVisible,
				isLargeFileEnabled,
				isPremiumPhotosEnabled,
				isFacebookEnabled: currentTemplate?.isFacebookEnabled ?? currentPrintType.isFacebookEnabled,
				isImagesScalingEnabled,
				isClipartScalingEnabled,
				isClipartsResizeEnabled,

				/* PDF, EPS, AI */
				isPdfWithRasterEnabled:
					currentTemplate?.isPdfWithRasterEnabled || currentPrintType.isPdfWithRasterEnabled,
				isLargePdfResizeEnabled:
					currentTemplate?.isLargePdfResizeEnabled || currentPrintType.isLargePdfResizeEnabled,
				isVectorPdfToRasterConversionEnabled: currentPrintType.isVectorPdfToRasterConversionEnabled,
				isVectorEpsToRasterConversionEnabled: currentPrintType.isVectorEpsToRasterConversionEnabled,
				isVectorAiToRasterConversionEnabled: currentPrintType.isVectorAiToRasterConversionEnabled,
				isSvgRecolorEnabled: currentPrintType.isSvgRecolorEnabled,
				// canImageChangeColors,
				canSVGImageChangeColors,

				// SVG recolor
				canPrintTypeRecolorImage,

				// Shapes
				canShapeCangeFillColor,
				canShapeChangeStrokeColor,
				canShapeChangeStrokeWidth,

				// TextArt
				canChangeTextArtColor,

				// Text
				canChangeFontColor,
				canChangeFontFamily,
				canChangeFontSize,
				canChangeFontWeight,
				canChangeFontItalic,
				canChangeLineSpacing,
				canChangeLetterSpacing,
				canChangeAlignment,
				canChangeVerticalAlignment,
				canChangeTextBoxMode,
				canChangeTextPathMode,

				isTextUppercase,

				minFontSize,
				maxFontSize,
				fontSizeList,
				minLineSpacing,
				maxLineSpacing,
				minLetterSpacing,
				maxLetterSpacing,
				canChangeShadow,
				canChangeShadowAngle,
				canChangeShadowBlur,
				canChangeShadowDistance,
				canChangeShadowColor,
				getDefaultShadowColor,

				// printType restrictions
				imageEditingToolSettingsToExclude: currentPrintType.imageEditingToolSettingsToExclude,
				isFixedLayoutItem,
				...checkTextStrokeRestrictions(item, currentPrintType),
				filteredPhotoEditorLabels,
				availablePhotoEditorTools,
				areCustomersImagesEnabled,
				isImageScalingEnabled,
			};
		},
		[
			areItemsPrintable,
			backgroundRules,
			collages,
			currentTemplate,
			getItems,
			getPrintMethodForSide,
			getSelectableColorsForText,
			imageGalleriesAvailable,
			masks.length,
			photoEditorToolsList,
			photoEditorLabelsFromBackend,
			price,
			printTypesWithEngraving,
			selectedSideId,
			shapes.length,
			shapesAndMasks?.forProduct,
			shapesAndMasks?.printTypesIDs,
			sidesCollages,
			isOrderDesignEditor,
			isInTestMode,
			isShopifyDevelopmentStore,
			isImageScalingEnabled,
		],
	);
};

export const useAvailableToolsFoItemValue = <Slice = AvailableTools,>(
	guid: string,
	selector: (tools: AvailableTools) => Slice = identity,
) => {
	const getAvailableToolsForItem = useAvailableToolsForItem();
	const getToolsRef = useRef(getAvailableToolsForItem);
	useEffect(() => {
		getToolsRef.current = getAvailableToolsForItem;
	});
	return useItemValueWithSelector(
		guid,
		useCallback((item) => selector(getToolsRef.current(guid && item.sideId ? item : null)), [selector, guid]),
		shallow,
	);
};
/**
 * Return a list of all available tools and values based on the selected item
 * @returns a list of available tools and values
 */
export const useAvailableToolsForSelectedItem = <Slice = AvailableTools,>(
	selector: (tools: AvailableTools) => Slice = identity,
) => {
	const selectedItemGuid = useAppStore((x) => x.selectedItemGuid);
	return useAvailableToolsFoItemValue(selectedItemGuid || '', selector);
};

/**
 * Get the list of supported file formats to upload
 * @param restrictions A design, product print type or design side (all extends the common upload restrictions)
 * @returns a list of supported file formats
 */
const getUploadFileFormats = (restrictions: ProductPrintType | Design | DesignSide | null) => {
	if (!restrictions) return [];
	const uploadFileFormats: string[] = [];

	if (restrictions.isPngUploadEnabled) {
		uploadFileFormats.push('.png');
		uploadFileFormats.push('.heic');
		uploadFileFormats.push('.webp');
	}

	if (restrictions.isJpgUploadEnabled) {
		uploadFileFormats.push('.jpg');
		uploadFileFormats.push('.jpeg');
	}

	if (restrictions.isSvgUploadEnabled) uploadFileFormats.push('.svg');

	if (restrictions.isAiUploadEnabled) uploadFileFormats.push('.ai');

	if (restrictions.isPdfUploadEnabled) uploadFileFormats.push('.pdf');

	if (restrictions.isEpsUploadEnabled) uploadFileFormats.push('.eps');

	return uploadFileFormats;
};

const allowedFileType = {
	1: ['.jpeg', '.jpg', '.png'],
	2: ['.jpeg', '.jpg', '.png', '.svg'],
};

type AllowedFileTypeForBackground = keyof typeof allowedFileType;

const filterUploadForBackground = (backgroundRules: BackgroundRule | undefined, allowedUploadFile: string[]) => {
	if (backgroundRules && backgroundRules?.imageTypeID) {
		const allowedBackground = allowedFileType[backgroundRules.imageTypeID as AllowedFileTypeForBackground];
		return allowedBackground.filter((value) => allowedUploadFile.includes(value));
	} else {
		return [''];
	}
};
