import { isEqual as isEqualLodash } from 'lodash';

// Memo: do not export any hook (to be put in hooks folder), store data (utils.ts) react component nor library imports from this file. Only pure functions or constants are allowed.

// #region constants

export const MOBILE_BREAKPOINT = 768;
export const MOBILE_BREAKPOINT_PX = MOBILE_BREAKPOINT + 'px';
export const SMALLDESK_BREAKPOINT = 980;
export const SMALLDESK_BREAKPOINT_PX = SMALLDESK_BREAKPOINT + 'px';
export const DESKTOP_UI_RIGHT_PANEL = 1080;
export const DESKTOP_UI_RIGHT_PANEL_PX = DESKTOP_UI_RIGHT_PANEL + 'px';
export const SMALLCONTAINER_BREAKPOINT = 1200;
export const SMALLCONTAINER_BREAKPOINT_PX = SMALLCONTAINER_BREAKPOINT + 'px';
export const SMALLVERTICAL_BREAKPOINT = 690;
export const SMALLVERTICAL_BREAKPOINT_PX = SMALLVERTICAL_BREAKPOINT + 'px';

// #endregion

// #region functions

export function isIOS() {
	return (/iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream) ||
	(navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
}

export function isMobile() {
	return window.innerWidth <= MOBILE_BREAKPOINT;
}

export function isSmallDesk() {
	return window.innerWidth <= SMALLDESK_BREAKPOINT;
}

export async function loadScript(url: string) {
	// Download the globals_3d.js file by appending the script tag to the head
	const script3D = document.createElement('script');
	script3D.src = url;
	script3D.async = true;
	document.head.appendChild(script3D);

	// Wait for the globals_3d.js file to be loaded
	await new Promise((resolve) => {
		script3D.onload = resolve;
	});
}

export const isMobileMatchMedia = window.matchMedia(`(max-width: ${MOBILE_BREAKPOINT_PX})`);

export const isSmallDeskMatchMedia = window.matchMedia(`(max-width: ${SMALLDESK_BREAKPOINT_PX})`);

export const isSmallContainerMedia = window.matchMedia(`(max-width: ${SMALLCONTAINER_BREAKPOINT_PX})`);

// Shallow object compare
export function isEqual(a: any, b: any, omitKeys?: string[]): boolean {
	let aKeys = Object.keys(a);
	let bKeys = Object.keys(b);

	// Omit keys from a and b
	if (omitKeys) {
		aKeys = aKeys.filter((key) => omitKeys.indexOf(key) === -1);
		bKeys = bKeys.filter((key) => omitKeys.indexOf(key) === -1);
	}

	if (aKeys.length !== bKeys.length) return false;

	for (const key of aKeys) {
		if (a[key] !== b[key]) return false;
	}

	return true;
}

export const isSmallContainer = () => window.innerWidth < 1200;

// String format with array reduce
export function stringFormat(str: string, ...args: string[]): string {
	return args.reduce((prev, current, idx) => prev.replace(`{${idx}}`, current), str);
}

export async function imageToFile(url: string) {
	const response = await fetch(url);
	const blob = await response.blob();
	return new File([blob], 'share.png', { type: blob.type });
}

export function openWindow(url: string, name: string, width: number, height: number) {
	const left = (window.screen.width - width) / 2;
	const top = (window.screen.height - height) / 2;
	return window.open(
		url,
		name,
		'width=' +
			width +
			',height=' +
			height +
			',left=' +
			left +
			',top=' +
			top +
			',menubar=no,location=no,status=no,toolbar=no',
	);
}

export function dataURLtoFile(dataurl: string, filename: string) {
	let arr = dataurl.split(',');

	if (arr !== null) {
		let mime = arr[0].split(':')[1].split(';')[0];
		let bstr = atob(arr[1]);
		let n = bstr.length;
		let u8arr = new Uint8Array(n);

		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		return new File([u8arr], filename, { lastModified: new Date().getTime(), type: mime });
	}
}

export function differenceIndex(origArr: any[], newArr: any[]) {
	if (origArr && newArr && origArr.length === newArr.length) {
		for (let i = 0; i < origArr.length; i++) {
			if (!isEqualLodash(origArr[i], newArr[i])) return i;
		}
	}
	return -1;
}

export function isTouchOnly() {
	return window.matchMedia('(hover: none)').matches;
}
// #endregion

export function convertUnicode(input: string) {
	const hex = input.codePointAt(0);
	return '&#' + hex + '';
}

export const humanize = (str: string) => {
	const frags = str.split('_');
	for (let i = 0; i < frags.length; i++) {
		frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
	}
	return frags.join(' ');
};

export const getImageDimension = async (src: string) => {
	const image = {
		width: 1,
		height: 1,
	}
	const tryGetImageInfo = (resolve: (i: typeof image) => void, tryNum = 0) => {
		const img = new Image();
		img.onload = () => {
			image.width = img?.naturalWidth;
			image.height = img?.naturalHeight;
			resolve(image);
		};
		img.onerror = () => {
			if (tryNum > 5) {
				image.width = 1;
				image.height = 1;
				resolve(image);
			} else {
				tryGetImageInfo(resolve, 1 + tryNum);
			}
		};
		img.src = src;
	};
	return await new Promise<typeof image>((resolve) => tryGetImageInfo(resolve));
}

export const isRoundEqual = (number1 : number, number2: number, roundValue: number = 100000) => {
	return Math.abs(number1 - number2) < (1 / roundValue);
}

// #region Measurement units

export const units = [
	{ name: 'cm', ratio: 1, id: 0 },
	{ name: 'mm', ratio: 10, id: 1 },
	{ name: 'dm', ratio: 0.1, id: 2 },
	{ name: 'm', ratio: 0.01, id: 3 },
	{ name: 'in', ratio: 0.3937007874015748, id: 4 },
	{ name: 'ft', ratio: 0.03280839895013123, id: 5 },
];

export const unitRatio = {
	cm: 1,
	mm: 10,
	dm: 0.1,
	m: 0.01,
	in: 0.3937007874015748,
	ft: 0.03280839895013123,
};

export const cmToIn = (cm?: number | null) => {
	if (cm == null) return 0;
	return cm * unitRatio.in;
};

// #endregion
