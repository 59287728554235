import { UseImages } from '../../../components/interfaces';
import useSuspanse from '../../useSuspanse';
import { isIOS }from '../../../shared/helpers';

interface FacebookSDK {
	login: (response: (response: any) => void) => void;
	getLoginStatus: (response: (response: {status: 'connected'|'not_authorized'|'unknown'}) => void) => void;
	api: (
		url: string,
		param1: ((response: any) => void) | { [index: string]: string },
		param2: ((response: any) => void) | { [index: string]: string },
	) => void;
}

declare const FB: FacebookSDK;

export class FacebookLoginError extends Error {
	constructor(message?: string, options?: ErrorOptions) {
		super(message, options);
		this.name = 'FacebookLoginError';
	}

	retry() {
		login();
	}
}

const getIsLoggedIn = () => new Promise<boolean>((resolve) => {
	FB.getLoginStatus((response) => resolve(response.status === 'connected'));
});

const login = () =>
	new Promise<void>((resolve, reject) => {
		FB.login((response) => {
			if (response.authResponse) {
				console.log('Facebook login successful.');
				resolve();
				return;
			}
			console.log('User cancelled login or did not fully authorize.');
			reject(new Error('Login error'));
		});
	});

const CheckAndLogin = async () => {
	const isLoggedIn = await getIsLoggedIn();
	if (!isLoggedIn) {
		await login();
	}
}

const fetchImages = async () => {
	const response = await new Promise<any>((resolve, reject) =>
		FB.api('/me/photos', { type: 'uploaded', fields: 'images' }, (response) => {
			if (!response || response.error) {
				reject(
					new Error('Error fetching facebook images', {
						cause: response?.error,
					}),
				);
				return;
			}
			resolve(response);
		}),
	);
	return response.data.map((row: any) => {
		const thumbnails = row.images;
		return {
			id: row.id,
			previewUrl: thumbnails[thumbnails.length - 1].source,
			url: thumbnails[0].source,
			name: '',
		};
	});
};

const useHandleFaceImageRequest = () => {
	try {
		const images = useSuspanse('facebook-images', async () => {
			const isLoggedIn = await getIsLoggedIn();
			if (!isLoggedIn) {
				// IOS permette l'apertura di nuove tab solo all'interno di un evento di click, quindi non si può usare in useEffect.
				if (isIOS()) {
					throw new FacebookLoginError('User not logged in');
				}
				await login();
			}
			return fetchImages();
		});
		return [images, null];
	} catch (error) {
		if (error instanceof Promise) {
			// continuo a propagare l'errore / promessa per far funzionare il suspense
			throw error;
		}
		// in caso sia lanciato un errore invece di una promassa, catturo errore.
		return [[], error];
	}
};

export const useFacebookLogin = () => {
	return CheckAndLogin;
}

const useFacebookImages: UseImages = () => {
	const [images, error] = useHandleFaceImageRequest();

	return {
		images,
		hasMore: false,
		fetchMore: () => Promise.resolve(),
		fetching: false,
		error,
	};
};

export default useFacebookImages;
