import { useZakekeTranslations } from '@zakeke/zakeke-customizer-react';
import type { Tool } from 'photoeditorsdk';

const usePhotoEditorTool = () => {
	const { T } = useZakekeTranslations();

	const photoEditorLabelsFromBackend = [
		'filter', // if active the tools, always active
		'focus', // optional
		'transform', // optional
		'brush', // optional
		'frame', // optional
		'adjustment', // optional
		'overlay', // optional
		/* 'sticker', */ // not available in pm
		/* 'text', */ // not available in pm
		/* 'library', */ // not available in pm
	];

	const photoEditorToolsList = {
		filter: 'filter',
		focus: 'focus',
		transform: 'transform',
		brush: 'brush',
		frame: 'frame',
		adjustment: 'adjustment',
		overlay: 'overlay',
		// sticker: Tool.STICKER,
		// text: Tool.TEXT,
		// custom: Tool.CUSTOM,
		// library: Tool.LIBRARY,
	} as { [index: string]: Tool };

	const photoEditorToolsLabels = new Map([
		['filter', T._('Filter')],
		['focus', T._('Focus')],
		['transform', T._('Transform')],
		['brush', T._('Brush')],
		['frame', T._('Frame')],
		['adjustment', T._('Adjustment')],
		['overlay', T._('Overlay')],
		/* ['sticker', T._('Sticker')], */
		/* ['text', T._('Text')], */
		/* ['library', T._('Library')], */
	]);
	return {
		photoEditorToolsLabels,
		photoEditorLabelsFromBackend,
		photoEditorToolsList,
	};
};

export default usePhotoEditorTool;
