import { FC } from 'react';
import styled from 'styled-components';

const InternalSpinner: FC<{ className?: string }> = ({ className }) => (
	<svg viewBox='0 0 50 50' className={className}>
		<circle className='path' cx='25' cy='25' r='20' fill='none' strokeWidth='2' />
	</svg>
);

const RotatingSpinner = styled(InternalSpinner)<{ $forceDarkBackText?: boolean; $small?: boolean }>`
	animation: rotate 1s linear infinite;
	width: ${(props) => (props.$small ? '15px' : '54px')};
	height: ${(props) => (props.$small ? '15px' : '54px')};

	& .path {
		stroke: ${(props) => (props.$forceDarkBackText ? '#ffffff' : props.theme.colors.primary)};
		stroke-linecap: round;
		animation: dash 1.5s ease-in-out infinite;
	}

	@keyframes rotate {
		100% {
			transform: rotate(360deg);
		}
	}

	@keyframes dash {
		0% {
			stroke-dasharray: 1, 150;
			stroke-dashoffset: 0;
		}
		50% {
			stroke-dasharray: 90, 150;
			stroke-dashoffset: -35;
		}
		100% {
			stroke-dasharray: 90, 150;
			stroke-dashoffset: -124;
		}
	}
`;

// This is used to avoid the parent of the spinner to scroll because of the rotation
const SpinnerNoOverlowContainer = styled.div<{ $small?: boolean }>`
	width: ${(props) => (props.$small ? '16px' : '54px')};
	height: ${(props) => (props.$small ? '16px' : '54px')};
	overflow: hidden;
`;

const SpinnerNoOverlow: FC<{ className?: string; forceDarkBackText?: boolean; small?: boolean }> = ({
	className,
	forceDarkBackText,
	small = false,
}) => {
	return (
		<SpinnerNoOverlowContainer className={className} $small={small}>
			<RotatingSpinner $forceDarkBackText={forceDarkBackText} $small={small} />
		</SpinnerNoOverlowContainer>
	);
};

const Spinner = styled(SpinnerNoOverlow)``;
export default Spinner;
