import Spinner from '../widgets/spinner';
import { Suspense } from 'react';
import { createPortal } from 'react-dom';
import { photoEditorPortal } from '../../shared/portals';
import { useAppStore } from '../../state/store';

const PhotoEditorRenderer = () => {
	const [photoEditors] = useAppStore((x) => [x.photoEditors]);
	return (
		<>
			{createPortal(
				photoEditors.map(([id, photoEditor]) => (
					<Suspense fallback={<Spinner />} key={id}>
						{photoEditor}
					</Suspense>
				)),
				photoEditorPortal,
			)}
		</>
	);
};

export default PhotoEditorRenderer;
