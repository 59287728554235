import { useMemo } from 'react';
import { keyBy } from 'lodash';
import { useTheme } from 'styled-components';
import {
	useZakekeCollages,
	useZakekeProduct,
	useZakekeSettings,
	useZakekeDrafts,
	useZakekePreviewMode,
	useZakekeParams,
} from '@zakeke/zakeke-customizer-react';

import { useAppStore, useGetItems } from '../state/store';
import { BackgroundEditingMenuTabId, backgroundEnabledTabs } from '../shared/helpers.background';
import { SettingButton, ToolButton, ToolButtonKey, ToolButtonType } from '../shared/tools';
import { ToolIconKey, ToolKey } from '../theme';
import ThemedIcon from '../theme/themedIcon';
import { isImageElement } from '../components/interfaces';
import { getButtonsListFiltered } from './common';
import { useAvailableToolsForItem } from './useAvailableTools';
import { useBackgroundEditingTools } from './useBackgroundEditingTools';
import useCustomizerEditModes from './useCustomizerEditModes';
import { useImageProvider } from './useImageProviders';
import useSelectedSide from './useSelectedSide';
import useTemplateLoader from './useTemplateLoader';
import useTemplatesPerVariant from './useTemplatesPerVariant';
import useToolsNames from './useToolsNames';

// filters are based on the key and type of the toolButtons
interface ToolButtonsFilter {
	key?: ToolButtonKey[];
	type?: ToolButtonType;
}

export type ToolsButtonsListOptions = {
	onMenuClose?: () => void;
};

/**
 * restituisce funzioni per recuperare la lista di icone e testi utili per muoversi tra le pagine
 */
const useToolsButtonsList = (options: ToolsButtonsListOptions = {}) => {
	const theme = useTheme();
	const [selectedVariantId, selectedAreaId, currentColor] = useAppStore((x) => [
		x.selectedVariantId,
		x.lastActiveAreaPerSide.get(x.selectedSideId) || 1,
		x.sideAreaBackgrounds.get(x.selectedSideId)?.get(x.lastActiveAreaPerSide.get(x.selectedSideId) || 1)
			?.fillColor ?? null,
	]);

	const collages = useZakekeCollages();
	// availableTools should not be memoized because it is used to check
	// if the tools are available and should not use any item guid
	// because they are generic and not depending on the selected item id
	const availableTools = useAvailableToolsForItem()();
	const { isEditDesign } = useCustomizerEditModes();
	const { templatesPerVariant, templatesPerVariantAvailable } = useTemplatesPerVariant();
	const product = useZakekeProduct();
	const { getDefaultTemplateForVariant } = useTemplateLoader();
	const { canSaveDraftDesing } = useZakekeSettings();
	const { drafts } = useZakekeDrafts();
	const { clipartsProvider } = useImageProvider();
	const previewMode = useZakekePreviewMode();
	const backgroundRules = useBackgroundEditingTools();
	const getItems = useGetItems();
	const side = useSelectedSide();
	const toolNames = useToolsNames();

	const backgroundImage = getItems()
		.filter((x) => x.sideId === side?.id)
		.filter(isImageElement)
		.find((image) => image.isBackgroundForArea === selectedAreaId);

	let preventTabs: BackgroundEditingMenuTabId[] = [];
	if (backgroundImage?.constraints?.canDelete === false) preventTabs.push('color');

	const hasBackgroundTabs =
		backgroundEnabledTabs(backgroundRules, availableTools, currentColor, preventTabs).length > 0;

	const defaultTemplateIdForVariant: number | null =
		getDefaultTemplateForVariant(selectedVariantId) || product!.defaultTemplateId;

	const hasTemplateButton =
		// !isEditDesign &&
		templatesPerVariantAvailable &&
		(templatesPerVariant.length > 1 ||
			(templatesPerVariant.length === 1 &&
				(defaultTemplateIdForVariant === null || defaultTemplateIdForVariant === 0)));

	const mySavedDesignConditions = !isEditDesign && canSaveDraftDesing && drafts.length > 0 && !previewMode.active;

	return useMemo(() => {
		const getThemedOptions = (config: {
			text?: string;
			defaultText: string;
			iconUrl?: string;
			defaultIconKey: ToolIconKey;
			position?: number;
			key: Exclude<ToolKey, 'palette'>;
		}) => {
			return {
				text: toolNames[config.key].text ?? '',
				longText: toolNames[config.key].longText ?? '',
				icon: <ThemedIcon iconUrl={config.iconUrl} defaultIconKey={config.defaultIconKey} />,
				position: config.position ?? +Infinity,
			};
		};
		// @ts-ignore
		const tools: ToolButton[] = [
			{
				show: true,
				menuPage: { page: 'design-elements' },
				type: 'tool',
				key: 'design-elements',

				...getThemedOptions(theme.tools.designElements),
			},
			{
				show: !!(availableTools.areCustomersUploadImagesEnabled && availableTools.isAnyUploadEnabled),
				menuPage: { page: 'upload-image', onClose: options.onMenuClose },
				type: 'tool',
				key: 'upload-image',
				disabled: availableTools.isMaxNumberImagesReached,
				tooltip: availableTools.isMaxNumberImagesReached && 'Maximum amount of images added to design',
				...getThemedOptions(theme.tools.uploadImage),
			},
			{
				show: availableTools.isClipartsImagesButtonEnabled,
				disabled: availableTools.isMaxNumberImagesReached,
				tooltip: availableTools.isMaxNumberImagesReached && 'Maximum amount of images added to design',
				menuPage: { page: 'images', imageProvider: clipartsProvider, onClose: options.onMenuClose },
				type: 'tool',
				key: 'cliparts',
				...getThemedOptions(theme.tools.cliparts),
			},
			{
				show: availableTools.isTextEnabled,
				menuPage: { page: 'edit-text' },
				tooltip: availableTools.isMaxNumberTextsReached && 'Maximum amount of texts added to design',
				disabled: availableTools.isMaxNumberTextsReached,
				menuPageMobile: { page: 'text-add', onClose: options.onMenuClose },
				type: 'tool',
				key: 'text',
				...getThemedOptions(theme.tools.text),
			},
			{
				show: availableTools.isTextArtEnabled,
				menuPage: { page: 'edit-text-art' },
				menuPageMobile: { page: 'textart-add', onClose: options.onMenuClose },
				type: 'tool',
				key: 'textart',
				...getThemedOptions(theme.tools.textArt),
			},
			{
				show: availableTools.isBackgroundEditingEnabled && hasBackgroundTabs,
				menuPage: { page: 'background-editing' },
				menuPageMobile: { page: 'background-editing' },
				type: 'tool',
				key: 'background',
				...getThemedOptions(theme.tools.background),
			},
			{
				show: hasTemplateButton,
				menuPage: { page: 'templates' },
				type: 'tool',
				key: 'templates',
				...getThemedOptions(theme.tools.templates),
			},
			{
				show: collages.length > 0 && theme.tools.collages.visible && !availableTools.hasCollage,
				menuPage: { page: 'collages' },
				subPages: [{ page: 'collage-layout' }],
				type: 'tool',
				key: 'collages',
				...getThemedOptions(theme.tools.collages),
			},
			{
				show: collages.length > 0 && theme.tools.collages.visible && availableTools.hasCollage,
				menuPage: { page: 'collage-layout' },
				menuPageMobile: { page: 'collage-edit' },
				subPages: [{ page: 'collage' }],
				type: 'tool',
				key: 'collage-layout',
				...getThemedOptions(theme.tools.collages),
			},
			{
				show: !!availableTools.areShapesEnabled && theme.tools.shapes.visible,
				menuPage: { page: 'shapes' },
				type: 'tool',
				key: 'shapes',
				...getThemedOptions(theme.tools.shapes),
			},
			{
				show: mySavedDesignConditions,
				menuPage: { page: 'drafts' },
				type: 'tool',
				key: 'drafts',
				...getThemedOptions(theme.tools.drafts),
			},
		].sort((a, b) => a.position - b.position);
		return {
			getToolsButtonsListFiltered: getButtonsListFiltered<ToolButton, ToolButtonsFilter>(tools),
		};
	}, [
		availableTools.areCustomersUploadImagesEnabled,
		availableTools.areShapesEnabled,
		availableTools.hasCollage,
		availableTools.isAnyUploadEnabled,
		availableTools.isBackgroundEditingEnabled,
		availableTools.isClipartsImagesButtonEnabled,
		availableTools.isMaxNumberImagesReached,
		availableTools.isMaxNumberTextsReached,
		availableTools.isTextArtEnabled,
		availableTools.isTextEnabled,
		clipartsProvider,
		collages.length,
		hasBackgroundTabs,
		hasTemplateButton,
		mySavedDesignConditions,
		options.onMenuClose,
		theme.tools,
		toolNames,
	]);
};

const useToolsSettings = (...args: Parameters<typeof useToolsButtonsList>) => {
	const theme = useTheme();
	const toolNames = useToolsNames();
	const { isEditDesign } = useCustomizerEditModes();

	const { hideVariants } = useZakekeParams();
	const product = useZakekeProduct();
	const side = useSelectedSide();
	const sideAvailablePrintTypes = useMemo(
		() => product?.printTypes.filter((x) => side?.printTypes.find((j) => j.id === x.id)) ?? [],
		[product, side],
	);
	const switchVariantConditions = !isEditDesign && product?.variants && product?.variants.length > 1 && !hideVariants;
	const printingMethodConditions = product && sideAvailablePrintTypes.length > 1;
	const switchVariant = useMemo<SettingButton>(() => {
		const switchVariantOptions = product?.variants.map((x) => ({
			label: x.name,
			value: x.id,
		}));
		return {
			show: switchVariantConditions && theme.tools.palette.visible,
			menuPage: { page: 'switch-variant' },
			type: 'settings',
			key: 'variants',
			text: toolNames.variants.text,
			longText: toolNames.variants.longText,
			icon: (
				<ThemedIcon
					iconUrl={theme.tools.variants.iconUrl}
					defaultIconKey={theme.tools.variants.defaultIconKey}
				/>
			),
			subPages: [],
			switchVariantOptions,
		};
	}, [
		product?.variants,
		switchVariantConditions,
		theme.tools.palette.visible,
		theme.tools.variants.iconUrl,
		theme.tools.variants.defaultIconKey,
		toolNames.variants.text,
		toolNames.variants.longText,
	]);
	const printMethod = useMemo<SettingButton>(() => {
		const printMethodsOptions = sideAvailablePrintTypes.map((x) => ({
			label: x.name,
			value: x.id,
		}));
		return {
			show: printingMethodConditions && theme.tools.printingMethods.visible,
			menuPage: { page: 'design-elements' },
			menuPageMobile: { page: 'print-methods' },
			type: 'settings',
			key: 'printing-methods',
			text: toolNames.printingMethods.text,
			longText: toolNames.printingMethods.longText,
			icon: (
				<ThemedIcon
					iconUrl={theme.tools.printingMethods.iconUrl}
					defaultIconKey={theme.tools.printingMethods.defaultIconKey}
				/>
			),
			subPages: [],
			printMethodsOptions,
		};
	}, [printingMethodConditions, sideAvailablePrintTypes, theme.tools.printingMethods, toolNames.printingMethods]);
	return {
		switchVariant,
		printMethod,
	};
};

const useToolsCollages = (...args: Parameters<typeof useToolsButtonsList>) => {
	const { getToolsButtonsListFiltered } = useToolsButtonsList(...args);
	const { collages, 'collage-layout': collageLayout } = keyBy(
		getToolsButtonsListFiltered({
			filterIn: { key: ['collages', 'collage-layout'] },
			ignoreVisibility: true,
		}),
		(i) => i.key,
	);
	const showCollages = collages.show || collageLayout.show;
	return {
		collages,
		collageLayout,
		showCollages,
	};
};

export default useToolsButtonsList;
export { useToolsSettings, useToolsCollages };
export type { ToolButton, SettingButton };
