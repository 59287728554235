import { TemplateCategoryItem, useZakekeTranslations } from '@zakeke/zakeke-customizer-react';
import { useState, FC, Suspense, useCallback } from 'react';
import styled, { useTheme } from 'styled-components';

import useTemplateLoader from '../../hooks/useTemplateLoader';
import useTemplatesPerVariant from '../../hooks/useTemplatesPerVariant';
import { isMobile } from '../../shared/helpers';
import { useAppStore } from '../../state/store';
import ThemedIcon from '../../theme/themedIcon';
import { Stack, FormControl, Columns, Input } from '../globals';
import AdvancedSelect from '../widgets/advancedSelect';
import { Dialog, DialogWindow, MessageDialog, useDialogManager } from '../widgets/dialogs';
import EmptyMessage from '../widgets/emptyMessage';
import Gallery, { GalleryLoader } from '../widgets/gallery';

const WindowContainer = styled(DialogWindow)`
	min-height: 480px;
	max-height: calc(100vh - 50px);
`;

export const useSelectTemplate = () => {
	const { loadTemplate, isTemplateValidForVariant } = useTemplateLoader();
	const [selectedVariantId] = useAppStore((x) => [x.selectedVariantId]);
	const { currentDialogId, showDialog, closeDialog } = useDialogManager();
	const { T } = useZakekeTranslations();

	return useCallback(
		(designId: number) => {
			if (!isTemplateValidForVariant(designId, selectedVariantId)) {
				showDialog(
					'error',
					<MessageDialog message={T._('Selected template is not valid for current variant.')} />,
				);
				return;
			}

			closeDialog(currentDialogId);
			return loadTemplate(designId);
		},
		[loadTemplate, isTemplateValidForVariant, selectedVariantId, currentDialogId, showDialog, closeDialog, T],
	);
};

type TemplatesProps = {
	templates: TemplateCategoryItem[];
	onSelect: (designId: number) => void;
};
const Templates: FC<TemplatesProps> = ({ templates, onSelect }) => {
	const { T } = useZakekeTranslations();
	const theme = useTheme();

	const templatesIcon = theme.tools.templates;

	if (templates.length === 0)
		return (
			<EmptyMessage
				icon={<ThemedIcon defaultIconKey={templatesIcon.defaultIconKey} iconUrl={templatesIcon.iconUrl} />}
				message={T._('No templates available.')}
			/>
		);

	return (
		<Gallery
			items={templates.map((template) => ({
				key: template.designID,
				previewUrl: template.previewUrl,
				label: template.name,
				reference: template,
			}))}
			onItemSelected={(item) => onSelect(item.reference.designID)}
			galleryImageBlockPadding={2}
			galleryImageInlinePadding={2}
			galleryImageHeight={isMobile() ? 120 : 140}
			galleryImageWidth={isMobile() ? 120 : 140}
		/>
	);
};

const DialogTplStack = styled(Stack)`
	min-height: 340px;
`;

const TemplateSelectionDialog = () => {
	const { T } = useZakekeTranslations();

	const [filter, setFilter] = useState('');

	const {
		selectedCategory,
		setSelectedCategory,
		isSingleCategory,
		categoriesOptions,
		templatesPerVariant,
		templatesPerVariantBySelectedCategory,
	} = useTemplatesPerVariant();
	const selectTemplate = useSelectTemplate();

	return (
		<Dialog
			showCloseButton={true}
			windowDecorator={WindowContainer}
			title={T._('Choose a design template to get started!')}
			buttons={[]}
		>
			<DialogTplStack $gap={16}>
				<Columns $gap={16}>
					{!isSingleCategory && (
						<FormControl label=''>
							<AdvancedSelect<
								(typeof categoriesOptions)[number]['options'][number],
								false,
								(typeof categoriesOptions)[number]
							>
								isClearable={false}
								isSearchable={false}
								options={categoriesOptions}
								value={selectedCategory}
								onChange={setSelectedCategory}
								placeholder={T._('Browse categories')}
							/>
						</FormControl>
					)}
					<FormControl label={''}>
						<Input
							value={filter}
							placeholder={T._('Search for templates')}
							onChange={(e) => setFilter(e.target.value)}
						/>
					</FormControl>
				</Columns>

				<Suspense fallback={<GalleryLoader />}>
					{!selectedCategory && (
						<Templates
							templates={templatesPerVariant.filter((x) => x.name.toLocaleLowerCase().includes(filter))}
							onSelect={selectTemplate}
						/>
					)}
					{selectedCategory && (
						<Templates
							templates={templatesPerVariantBySelectedCategory.filter((x) =>
								x.name.toLocaleLowerCase().includes(filter),
							)}
							onSelect={selectTemplate}
						/>
					)}
				</Suspense>
			</DialogTplStack>
		</Dialog>
	);
};

export default TemplateSelectionDialog;
