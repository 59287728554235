import { useZakekeDesignEditorMode } from '@zakeke/zakeke-customizer-react';
import { useMemo } from 'react';

export type UIModeSettings = {
	mustCheckMandatoryItem: boolean;
	avoidWelcomeMessage: boolean;
}
const useUIModeSettings = (): UIModeSettings => {
	const designEditorMode = useZakekeDesignEditorMode()
	return useMemo(() => {
		if (designEditorMode.enabled) {
			return {
				mustCheckMandatoryItem: false,
				avoidWelcomeMessage: true,
			};
		}
		return {
			mustCheckMandatoryItem: true,
			avoidWelcomeMessage: false,
		};
	}, [designEditorMode.enabled]);
}

export default useUIModeSettings;
