import Select, {
	CSSObjectWithLabel,
	GroupBase,
	Props,
	StylesConfig,
	components,
	DropdownIndicatorProps,
} from 'react-select';
import { ExtendedProps } from './extendedProps';
import { ReactComponent as AngleDown } from '../../../shared/icons/chevron-down.svg';
import React from 'react';
import { DefaultTheme, useTheme } from 'styled-components';
import { advancedSelectMenuPortal } from '../../../shared/portals';

function styles<Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(
	fullWidth: boolean,
	customStyles: StylesConfig<Option, IsMulti, Group> | undefined,
	theme: DefaultTheme,
): StylesConfig<Option, IsMulti, Group> {
	type getCustomStylesType<S> = (name: keyof StylesConfig<Option, IsMulti, Group>, state: S) => CSSObjectWithLabel;
	const getCustomStyles: getCustomStylesType<any> = (name, state) => {
		return customStyles?.[name]?.({}, state) ?? {};
	};
	return {
		...(customStyles ?? {}),
		option: (provided, state) => {
			return {
				...provided,
				fontSize: 14,
				color: state.isSelected ? 'white' : 'black',
				...getCustomStyles('option', state),
			} as CSSObjectWithLabel;
		},
		valueContainer: (provided, state) => {
			return {
				...provided,
				fontSize: 14,
				...getCustomStyles('valueContainer', state),
			} as CSSObjectWithLabel;
		},
		menu: (provided, state) => {
			return {
				...provided,
				zIndex: 2,
				...getCustomStyles('menu', state),
			} as CSSObjectWithLabel;
		},
		menuPortal: (provided, state) => {
			return {
				...provided,
				zIndex: 300,
				...getCustomStyles('menuPortal', state),
			} as CSSObjectWithLabel;
		},
		control: (provided, state) => {
			return {
				...provided,
				position: 'relative',
				borderWidth: 1,
				borderStyle: 'solid',
				borderRadius: 8,
				borderColor: state.isFocused ? theme.colors.primary : theme.colors.borders,
				backgroundColor: theme.colors.backgroundMain,
				boxShadow: 'none',
				minHeight: 40,
				height: 40,
				transition: 'border-color 0.1s',
				':hover': {
					borderColor: state.isFocused ? theme.colors.primary : theme.colors.borders,
				},
				...getCustomStyles('control', state),
			} as CSSObjectWithLabel;
		},
		container: (provided, state) => {
			return {
				...provided,
				width: fullWidth ? '100%' : 'auto',
				...getCustomStyles('container', state),
			} as CSSObjectWithLabel;
		},
		indicatorSeparator: (provided, state) => {
			return {
				...provided,
				backgroundColor: 'transparent',
				...getCustomStyles('indicatorSeparator', state),
			} as CSSObjectWithLabel;
		},
		dropdownIndicator: (provided, state) => {
			return {
				...provided,
				color: state.isFocused ? theme.colors.primary : theme.colors.iconMain,
				svg: {
					fill: 'currentColor',
					height: '20px',
					width: '20px',
				},
				...getCustomStyles('dropdownIndicator', state),
			} as CSSObjectWithLabel;
		},
		singleValue: (provided, state) => {
			return {
				...provided,
				color: theme.colors.textMain,
			} as CSSObjectWithLabel;
		},
	};
}

const DropdownIndicator = (props: DropdownIndicatorProps) => {
	return (
		<components.DropdownIndicator {...props}>
			<AngleDown />
		</components.DropdownIndicator>
	);
};

const AdvancedSelect = <Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(
	props: Props<Option, IsMulti, Group> & ExtendedProps<Option>,
) => {
	const theme = useTheme();
	return (
		<Select
			menuPlacement='auto'
			menuPortalTarget={advancedSelectMenuPortal}
			{...props}
			styles={{ ...styles<Option, IsMulti, Group>(props.fullWidth ?? false, props.styles, theme) }}
			components={{
				DropdownIndicator: DropdownIndicator as unknown as React.ComponentType<
					DropdownIndicatorProps<Option, IsMulti, Group>
				>,
			}}
		/>
	);
};

export default AdvancedSelect;
